<template>
  <div>
    <div class="app-container">
      <ExitIntent />
      <PopupPromo />
      <div
        id="site-container"
        :class="{ 'site-container': true, unstuck: unstuck && showHeader }"
      >
        <SiteHeader v-show="showHeader && !inMobileApp" />
        <main id="main">
          <nuxt />
        </main>
        <SiteFooter v-show="showFooter && !inMobileApp"></SiteFooter>
        <div v-if="mobileNotificationsOpen" class="modal in-bottom slide-up">
          <AlertNotifications :mobile="true"></AlertNotifications>
        </div>
        <div
          v-show="mobileSearchOpen"
          class="search-modal modal in-bottom slide-up"
        >
          <Search />
        </div>
      </div>
    </div>
    <SideCart />
    <LogInModal v-if="modalOpen" @close="modalOpen = false" />
    <PPTOToastification v-if="!inMobileApp" />
    <TermsAndConditionsPopup
      v-if="showTermsAndConditions"
      @close="$store.dispatch('setTermsAndConditions', false)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  identifyUser,
  loadUser,
  userLoadedOnServer,
  pushPageView,
} from '~/../common/utils/user'
import { baseURL } from '~/../common/utils/url'
import { getHeaderHeight } from '~/../common/components/Header/utils'
import AlertNotifications from '~/components/Header/AlertBell/AlertNotifications'
import ga4 from '~/../common/utils/ga4'

export default {
  name: 'DefaultLayout',
  components: {
    AlertNotifications,
    SiteHeader: () => import('~/components/Header/SiteHeader'),
    SideCart: () => import('~/components/Cart/SideCart'),
    SiteFooter: () => import('~/components/Layout/SiteFooter'),
    LogInModal: () => import('~/../common/components/LogIn/LogInModal'),
    Search: () => import('~/components/Header/Search'),
    PPTOToastification: () =>
      import('~/components/PPTO/PPTOToastification.vue'),
    ExitIntent: () => import('~/components/ExitIntent.vue'),
    PopupPromo: () => import('~/components/PopupPromo.vue'),
    TermsAndConditionsPopup: () =>
      import('~/../common/components/TermsAndConditionsPopup.vue'),
  },
  data() {
    return {
      unstuck: false,
      headerChangeHeight: 0,
      lastScrollPosition: 0,
      mobileSearchActive: false,
    }
  },
  head() {
    return {
      title: this.seoData.page_title,
      link: this.link(),
      meta: this.meta(),
      bodyAttrs: {
        class: this.toggleCart ? 'no-overflow' : '',
      },
      __dangerouslyDisableSanitizers: ['script'],
    }
  },
  computed: {
    ...mapGetters([
      'mobileSearchOpen',
      'seoForPath',
      'toggleCart',
      'showHeader',
      'showTermsAndConditions',
      'showFooter',
      'inMobileApp',
      'modalOpen',
      'notificationsOpen',
      'mobileNotificationsOpen',
      'isProdEnv',
    ]),
    showPromoBanner() {
      // When banner state changes, re-adjust app content div so not covered by header
      this.adjustAppContentSize()
      return this.$store.getters.showPromoBanner
    },
    seoData() {
      const data = this.seoForPath(this.$route.path)
      if (!data) {
        return {
          page_title:
            // keep this at 60 characters or less!
            'Buy Limited Production Wines Online | The Wine Access Store',
          // This would be used for a <h1> in <head> but that is not supported
          h1_tag: '',
          description: process.description, // pull from head.js
        }
      }
      return data
    },
  },
  watch: {
    $route(to, from) {
      // internally, referrer is the page that we are coming from during
      // a page transition.
      if (process.client) {
        const queryParams = new URLSearchParams(from.params).toString()
        const referrerPath =
          window.location.protocol.concat('//').concat(window.location.host) +
          from.path
        const referrerUrl = queryParams
          ? referrerPath + '?' + queryParams
          : referrerPath

        // Delay this call to try and guarantee that the document
        // title has been updated at the point of sending the pageView
        setTimeout(() => {
          pushPageView(this.$axios, {
            name: this.pathToPushName(to.name, to),
            properties: {
              referrer: referrerUrl,
              path: to.fullPath,
              url: window.location.href,
              title: document.title,
            },
          })
        }, 3000)
      }
    },
    showHeader(newValue) {
      if (newValue) {
        this.adjustAppContentSize()
      }
    },
  },
  async beforeMount() {
    // Load user-data client-side if we didn't load it server-side
    if (!userLoadedOnServer(this.$auth)) {
      await loadUser({
        $auth: this.$auth,
        $axios: this.$axios,
        $cookies: this.$cookies,
        store: this.$store,
        query: this.$route.query,
      })
    }
    if (this.$auth.loggedIn) {
      ga4.config({ user: this.$auth.user })
    } else {
      ga4.config()
    }
  },
  async mounted() {
    this.adjustAppContentSize()
    // Listen for resize events
    window.addEventListener('resize', this.adjustAppContentSize)
    window.addEventListener('scroll', this.unstickHeaderOnScroll)
    if (this.$auth.loggedIn) {
      identifyUser(this.$auth, this.$cookies)
    }
    if (!this.isProdEnv) {
      // Add ?force_error=true to the end of a url to force an unhandled 500 error in axios
      if (this.$route.query.force_error) {
        await this.$axios.get('/api/500error/')
      }
    }
    pushPageView(this.$axios, {
      name: this.pathToPushName(this.$route.name, this.$route),
      properties: {
        path: this.$route.fullPath,
        url: window.location.href,
        title: document.title,
        referrer: document.referrer,
      },
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustAppContentSize)
    window.removeEventListener('scroll', this.unstickHeaderOnScroll)
  },
  methods: {
    // Adjusts header height to calculate unstuck class
    adjustAppContentSize() {
      if (process.client) {
        // Wait for child Header component finish rendering before checking height and adjusting
        this.$nextTick(() => {
          const height = getHeaderHeight()
          this.headerChangeHeight = window.innerHeight - height * 2
          this.unstickHeaderOnScroll()
        })
      }
    },
    pathToPushName(name, to) {
      const wagtailTitle = document.getElementById('wagtail-page-title')
      if (name === 'club-gifting-slug') {
        return `${to.params.slug}-gifting`
      }
      if (name === 'store-category-slug' && to.params.category === 'occasion') {
        return to.params.slug
      }
      if (wagtailTitle) {
        return wagtailTitle.textContent
      }
      const map = {
        index: 'Home',
        'catalog-slug': document.title,
        'store-category-slug': 'Product Listing Page',
      }
      if (name in map) {
        return map[name]
      }
      return this.$route.name
    },
    unstickHeaderOnScroll() {
      if (process.client) {
        // Wait for child Header component finish rendering before checking scroll position
        this.$nextTick(() => {
          const scroll = window.pageYOffset
          this.unstuck =
            this.lastScrollPosition > this.headerChangeHeight &&
            this.lastScrollPosition < scroll
          this.lastScrollPosition = scroll
        })
      }
    },
    link() {
      const theBaseURL = baseURL()
      const currentPath = this.$route.fullPath
      let href = theBaseURL + currentPath
      const url = new URL(theBaseURL + currentPath)
      const pageValue = url.searchParams.get('page')

      // Remove all search params, restore page if necessary
      url.search = ''
      if (pageValue) url.searchParams.set('page', pageValue)

      href = url.toString()

      return [
        {
          // Use 'hid' to prevent re-adding a new tag on every route change
          hid: 'canonical',
          rel: 'canonical',
          href,
        },
      ]
    },
    meta() {
      const ogImageItems = this.seoData.og_image_url
        ? [
            {
              hid: 'og:image',
              name: 'og:image',
              property: 'og:image',
              content: this.seoData.og_image_url,
            },
            {
              hid: 'og:image:width',
              name: 'og:image:width',
              property: 'og:image:width',
              content: this.seoData.og_image_width,
            },
            {
              hid: 'og:image:height',
              name: 'og:image:height',
              property: 'og:image:height',
              content: this.seoData.og_image_height,
            },
            {
              hid: 'og:image:type',
              name: 'og:image:type',
              property: 'og:image:type',
              content: this.seoData.og_image_type,
            },
          ]
        : []
      return [
        {
          hid: 'created',
          name: 'created',
          content: this.$moment(Date.now()).format('Do MMM YYYY h:mm'),
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: this.$route.fullPath,
        },
        {
          hid: 'description',
          name: 'description',
          content: this.seoData.description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.seoData.description,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.seoData.page_title,
        },
        {
          hid: 'format-detection',
          name: 'format-detection',
          property: 'format-detection',
          content: 'telephone=no',
        },
        {
          hid: 'apple-itunes-app',
          name: 'apple-itunes-app',
          content: `app-id=1642804318, app-argument=${
            baseURL() + this.$route.fullPath
          }`,
        },
        ...ogImageItems,
      ]
    },
    reload() {
      if (
        window.location.hostname.includes('-ab') ||
        window.location.hostname === 'www.wineaccess.com'
      ) {
        /* eslint-disable no-console */
        console.log('reloading...')
        window.location = '/'
        /* eslint-enable */
      }
    },
  },
}
</script>
