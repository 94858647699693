export const getHeaderHeight = () => {
  const header = document.getElementsByClassName('site-header-container')[0]
  if (process.client) {
    return header ? header.clientHeight : 0
  } else {
    return 130
  }
}

export const debounce = (func, delay) => {
  let timerId
  let isPending = false

  function debounced(...args) {
    isPending = true
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      func(...args)
      isPending = false
    }, delay)
  }

  debounced.isPending = () => isPending

  debounced.cancel = () => {
    clearTimeout(timerId)
    isPending = false
  }

  return debounced
}
